import { map, Observable } from 'rxjs';
import { Apollo, MutationResult } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client';
import {
  GET_AGENCIES,
  GET_MAKES,
  GET_MODELS,
  GET_TRIMS,
  SAVE_CAR_VALUATION_FORM,
} from './queries.graphql';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  CarContactFormModel,
  MakeModel,
  ModelModel,
  TrimModel,
} from '../models/car-valuation.model';
import { AgenziaModel } from '@tuacar-workspace/core';

@Injectable({ providedIn: 'root' })
export class CarService {
  constructor(
    private apollo: Apollo,
    private http: HttpClient,
  ) {}

  getMakes(): Observable<MakeModel[]> {
    return this.apollo
      .watchQuery({
        query: GET_MAKES,
        returnPartialData: false,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((result: ApolloQueryResult<any>) => {
          const makes = result.data.autosupermarketMakes as MakeModel[];
          return makes;
        }),
      );
  }

  getModels(makeId: number): Observable<ModelModel[]> {
    return this.apollo
      .watchQuery({
        query: GET_MODELS,
        variables: {
          makeId,
        },
        returnPartialData: false,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((result: ApolloQueryResult<any>) => {
          const models = result.data.autosupermarketModels as MakeModel[];
          return models;
        }),
      );
  }

  getTrims(modelId: number): Observable<TrimModel[]> {
    return this.apollo
      .watchQuery({
        query: GET_TRIMS,
        variables: {
          modelId,
        },
        returnPartialData: false,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((result: ApolloQueryResult<any>) => {
          const trims = result.data.autosupermarketTrims as MakeModel[];
          return trims;
        }),
      );
  }

  getAgencies(): Observable<AgenziaModel[]> {
    return this.apollo
      .watchQuery({
        query: GET_AGENCIES,
        variables: {
          tenant: 'tenant_001',
        },
        returnPartialData: false,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((result: ApolloQueryResult<any>) => {
          const agencies = result.data.agenziePublic as AgenziaModel[];
          return agencies;
        }),
      );
  }

  saveForm(model: CarContactFormModel) {
    return this.apollo
      .mutate({
        mutation: SAVE_CAR_VALUATION_FORM,
        variables: {
          input: model,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((result: MutationResult) => {
          return result.data;
        }),
      );
  }
}
