<div nz-row class="__container">
  <div nz-col nzSpan="24" style="text-align: center">
    <img
      src="https://lg.prod1.indicataapis.com/rest/resources/IMG/TuacarHeaderWhite.png"
    />
  </div>

  <div nz-col nzSpan="24">
    <nz-result
      nzStatus="success"
      nzTitle="Richiesta informazioni inviata"
      nzSubTitle="Verrai contattato al più presto da un nostro consulente per fornirti
      tutte le informazioni necessarie"
    >
    </nz-result>
  </div>
</div>
