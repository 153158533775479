<nz-layout>
  <nz-header>
    <img
      src="https://lg.prod1.indicataapis.com/rest/resources/IMG/TuacarHeaderWhite.png"
    />
  </nz-header>
  <nz-content>
    <form nz-form #form="ngForm">
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="make"
          >Marca</nz-form-label
        >
        <nz-form-control
          [nzSm]="14"
          [nzXs]="24"
          nzErrorTip="Inserire la marca dell'auto"
        >
          <nz-select
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="Seleziona marca"
            [(ngModel)]="formValue$$().makeId"
            (ngModelChange)="loadModels()"
            name="make"
            required
          >
            <nz-option
              *ngFor="let item of makes$$(); trackBy: trackByMethod"
              [nzLabel]="item.name"
              [nzValue]="item.id"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="model"
          >Modello</nz-form-label
        >
        <nz-form-control
          [nzSm]="14"
          [nzXs]="24"
          nzErrorTip="Inserire il modello dell'auto"
        >
          <nz-select
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="Seleziona modello"
            [(ngModel)]="formValue$$().modelId"
            (ngModelChange)="loadTrims()"
            name="model"
            required
          >
            <nz-option
              *ngFor="let item of models$$(); trackBy: trackByMethod"
              [nzLabel]="item.name"
              [nzValue]="item.id"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="trim"
          >Versione</nz-form-label
        >
        <nz-form-control
          [nzSm]="14"
          [nzXs]="24"
          nzErrorTip="Inserire la versione dell'auto"
        >
          <nz-select
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="Seleziona versione"
            [(ngModel)]="formValue$$().trimId"
            name="trim"
            required
          >
            <nz-option
              *ngFor="let item of trims$$(); trackBy: trackByMethod"
              [nzLabel]="item.name"
              [nzValue]="item.id"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label
          [nzSm]="6"
          [nzXs]="24"
          nzRequired
          nzFor="registrationDate"
          >Data immatricolazione</nz-form-label
        >
        <nz-form-control
          [nzSm]="14"
          [nzXs]="24"
          nzErrorTip="Inserire la data di immatricolazione"
        >
          <nz-date-picker
            nzMode="month"
            name="registrationDate"
            [(ngModel)]="formValue$$().registrationDate"
            required
          ></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="km"
          >Km</nz-form-label
        >
        <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Inserire i km">
          <nz-input-number
            id="km"
            name="km"
            [(ngModel)]="formValue$$().km"
            required="true"
            [nzMin]="0"
            [nzStep]="1000"
            [nzFormatter]="formatterKm"
          ></nz-input-number>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="name"
          >Nome</nz-form-label
        >
        <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Inserire in nome">
          <input
            nz-input
            name="name"
            [(ngModel)]="formValue$$().name"
            placeholder="Inserire il nome"
            required
          />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="lastName"
          >Cognome</nz-form-label
        >
        <nz-form-control
          [nzSm]="14"
          [nzXs]="24"
          nzErrorTip="Inserire il cognome"
        >
          <input
            nz-input
            name="lastName"
            [(ngModel)]="formValue$$().lastName"
            placeholder="Inserire il cognome"
            required
          />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="email"
          >E-mail</nz-form-label
        >
        <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Inserire la mail">
          <input
            nz-input
            name="email"
            [(ngModel)]="formValue$$().email"
            placeholder="Inserire la mail"
            required
          />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="phone"
          >Telefono</nz-form-label
        >
        <nz-form-control
          [nzSm]="14"
          [nzXs]="24"
          nzErrorTip="The input is not valid E-mail!"
        >
          <input
            nz-input
            name="phone"
            [(ngModel)]="formValue$$().phone"
            placeholder="Inserire il telefono"
            required
          />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="interestedOf"
          >Sono interessato a</nz-form-label
        >
        <nz-form-control [nzSm]="14" [nzXs]="24" style="padding-top: 8px">
          <label
            nz-checkbox
            name="buyNewCar"
            [(ngModel)]="formValue$$().buyNewCar"
          >
            <span> Comprare un'auto nuova </span> </label
          ><br />
          <label
            nz-checkbox
            name="buyUsedCar"
            [(ngModel)]="formValue$$().buyUsedCar"
          >
            <span> Comprare un'auto usata </span> </label
          ><br />
          <label nz-checkbox name="sellCar" [(ngModel)]="formValue$$().sellCar">
            <span> Vendere un'auto </span>
          </label>
          <br />
          <label nz-checkbox name="rentCar" [(ngModel)]="formValue$$().rentCar">
            <span> Noleggio a lungo termine </span>
          </label>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="agency"
          >Agenzia</nz-form-label
        >
        <nz-form-control
          [nzSm]="14"
          [nzXs]="24"
          nzErrorTip="Inserire l'agenzia"
        >
          <nz-select
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="Seleziona l'agenzia"
            [(ngModel)]="formValue$$().agencyCode"
            name="agency"
            required
          >
            <nz-option
              *ngFor="let item of agencies$$(); trackBy: trackByMethod"
              [nzLabel]="
                item.description +
                ' - ' +
                item.indirizzo +
                ' - ' +
                item.cap +
                ' ' +
                item.citta +
                ' (' +
                item.provincia +
                ')'
              "
              [nzValue]="item.code"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24">Commenti</nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24">
          <nz-textarea-count [nzMaxCharacterCount]="2000">
            <textarea
              name="comment"
              nz-input
              rows="5"
              placeholder="Commenti"
              [(ngModel)]="formValue$$().comment"
            ></textarea>
          </nz-textarea-count>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control
          [nzXs]="{ span: 24, offset: 0 }"
          style="text-align: center"
        >
          <button
            nz-button
            nzType="primary"
            class="__button-ask-valuation"
            nzSize="large"
            (click)="saveForm()"
          >
            RICHIEDI VALUTAZIONE
          </button>
        </nz-form-control>
      </nz-form-item>
    </form>
  </nz-content>
</nz-layout>

<!-- {{ formValue$$() | json }} -->
