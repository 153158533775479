import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from '@tuacar-workspace/ui';

@Component({
  selector: 'app-car-valuation-success',
  standalone: true,
  imports: [CommonModule, UiModule],
  templateUrl: './car-valuation-success.component.html',
  styleUrl: './car-valuation-success.component.scss',
})
export class CarValuationSuccessComponent {}
