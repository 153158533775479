import { gql } from 'apollo-angular';

export const GET_MAKES = gql`
  query autosupermarketMakes {
    autosupermarketMakes {
      id
      name
    }
  }
`;

export const GET_MODELS = gql`
  query autosupermarketModels($makeId: Int!) {
    autosupermarketModels(makeId: $makeId) {
      id
      name
    }
  }
`;

export const GET_TRIMS = gql`
  query autosupermarketTrims($modelId: Int!) {
    autosupermarketTrims(modelId: $modelId) {
      id
      name
    }
  }
`;

export const GET_AGENCIES = gql`
  query agenziePublic($tenant: String!) {
    agenziePublic(tenant: $tenant) {
      code
      description
      indirizzo
      cap
      citta
      provincia
    }
  }
`;

export const SAVE_CAR_VALUATION_FORM = gql`
  mutation saveCarValuationForm($input: CarValuationFormInputDtoInput!) {
    saveCarValuationForm(form: $input) {
      makeId
    }
  }
`;
