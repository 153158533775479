/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Component,
  Signal,
  inject,
  model,
  signal,
  viewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from '@tuacar-workspace/ui';
import { CarService } from './services/car-valuation.service';
import { FormsModule, NgForm } from '@angular/forms';
import {
  CarContactFormModel,
  MakeModel,
  ModelModel,
  TrimModel,
} from './models/car-valuation.model';
import { AgenziaModel } from '@tuacar-workspace/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-car-valuation',
  standalone: true,
  imports: [CommonModule, UiModule, FormsModule],
  templateUrl: './car-valuation.component.html',
  styleUrl: './car-valuation.component.scss',
})
export class CarValuationComponent {
  private carValuationService = inject(CarService);
  private router= inject(Router);

  form$$: Signal<NgForm | undefined> = viewChild('form');

  makes$$ = signal<MakeModel[]>([]);
  models$$ = signal<ModelModel[]>([]);
  trims$$ = signal<TrimModel[]>([]);
  agencies$$ = signal<AgenziaModel[]>([]);

  formValue$$ = model<CarContactFormModel>({});

  formatterKm = (value: number): string => {
    if (value) return `${value}`;
    else return '0';
  };

  constructor() {
    this.carValuationService.getMakes().subscribe((makes) => {
      this.makes$$.set(makes);
    });

    this.carValuationService.getAgencies().subscribe((agencies) => {
      this.agencies$$.set(agencies);
    });
  }

  loadModels() {
    this.formValue$$.set({
      ...this.formValue$$(),
      modelId: undefined,
      trimId: undefined,
    });

    const makeId = this.formValue$$()?.makeId;
    this.carValuationService.getModels(makeId || 0).subscribe((models) => {
      this.models$$.set(models);
    });
  }

  loadTrims() {
    this.formValue$$.set({
      ...this.formValue$$(),
      trimId: undefined,
    });
    const modelId = this.formValue$$()?.modelId;
    this.carValuationService.getTrims(modelId || 0).subscribe((trims) => {
      this.trims$$.set(trims);
    });
  }

  saveForm() {
    if (this.form$$()?.invalid) {
      Object.values(this.form$$()?.controls || {}).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }

    this.carValuationService.saveForm(this.formValue$$()).subscribe(() => {
      this.formValue$$.set({});
      this.form$$()?.resetForm();
      this.router.navigate(['/car-valuation/success']);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trackByMethod(index: number, el: any): number {
    return el.id;
  }
}
